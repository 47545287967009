<template>
  <div class="attachment" v-if="data">
    <div class="attach-title">附件（{{ data.length }}）</div>
    <div class="flex attachment-list flex-between">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="flex flex-between attachment-item"
      >
        <div class="attachment-name flex">
          <img
            v-if="getFileType(item.attachment_name) == 'pdf'"
            src="~@/assets/course_PDF.png"
            alt=""
          />
          <img
            v-else-if="
              getFileType(item.attachment_name) == 'doc' ||
              getFileType(item.attachment_name) == 'docx'
            "
            src="~@/assets/course_word.png"
            alt=""
          />
          <img
            v-else-if="getFileType(item.attachment_name) == 'xlsx'"
            src="~@/assets/course_elxs.png"
            alt=""
          />
          <img v-else src="~@/assets/course_default.png" alt="" />
          <div class="text-ellipsis">{{ item.attachment_name }}</div>
        </div>
        <a-space align="center" size="middle">
          <a href="javascript:void(0);">
            <EyeOutlined @click="openPreviewModal(item)"/>
          </a>
          <a :href="item.attachment_src" :download="item.attachment_name">
            <DownloadOutlined />
          </a>
        </a-space>
        <a-modal
          v-model:visible="previewModalVisable"
          :title="previewModalTitle"
          width="100%"
          :bodyStyle="{
            overflow: 'hidden',
            overflowY: 'scroll',
          }"
          wrap-class-name="full-modal"
          @close="closePreviewModal"
          :footer="false"
        >
          <vue-office-docx
            v-if="previewModalType == 'docx'"
            :src="previewModalUrl"
            style="height: 100%"
          />
          <vue-office-excel
            v-else-if="previewModalType == 'xlsx'"
            :src="previewModalUrl"
            style="height: 100%"
          />
          <vue-office-pdf
            v-else-if="previewModalType == 'pdf'"
            :src="previewModalUrl"
            style="height: 100%"
          />
          <img
            v-else-if="
              previewModalType == 'png' ||
              previewModalType == 'jpg' ||
              previewModalType == 'jpeg'
            "
            :src="previewModalUrl"
            style="width: 100%"
          />
          <div
            v-else
            style="
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <a-result
              status="warning"
              title="支持预览docx、xlsx、pdf、jpg、jpeg、png文件，doc、xls文件暂不支持预览"
            ></a-result>
            <!-- <p style="font-size:30px;font-weight:bold;text-align:center">支持预览docx、xlsx、pdf、jpg、jpeg、png文件，doc、xls文件暂不支持预览</p> -->
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs,ref } from "@vue/runtime-core";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons-vue";
//引入VueOfficeDocx组件
import VueOfficeDocx from "@vue-office/docx";
//引入相关样式
import "@vue-office/docx/lib/index.css";
//引入VueOfficeExcel组件
import VueOfficeExcel from "@vue-office/excel";
//引入相关样式
import "@vue-office/excel/lib/index.css";
import VueOfficePdf from "@vue-office/pdf";
export default defineComponent({
  name: "Attachment",
  components: {
    DownloadOutlined,
    EyeOutlined,
    VueOfficeDocx,
    VueOfficeExcel,
    VueOfficePdf,
  },
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  setup(props) {
    const getFileType = (_filename) => {
      let suffix = _filename.substring(_filename.lastIndexOf(".") + 1);
      return suffix;
    };
    // 打开预览窗口
    const previewModalVisable = ref(false);
    const previewModalUrl = ref();
    const previewModalType = ref();
    const previewModalTitle = ref();
    const openPreviewModal = (record) => {
      previewModalTitle.value = record.attachment_name;
      previewModalType.value = record.attachment_src.slice(
        record.attachment_src.lastIndexOf(".") + 1
      );
      previewModalUrl.value = record.attachment_src;
      previewModalVisable.value = true;
    };
    const closePreviewModal = () => {
      previewModalUrl.value = null;
      previewModalTitle.value = null;
      previewModalType.value = null;
      previewModalVisable.value = false;
    };
    return {
      getFileType,
      previewModalVisable,
      previewModalUrl,
      previewModalType,
      previewModalTitle,
      openPreviewModal,
      closePreviewModal,
    };
  },
});
</script>
<style lang="less" scoped>
.attachment {
  .attach-title {
    position: relative;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 20px;
    color: #212531;
    border-bottom: 1px solid #f6f8f9;
    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #1276cb;
      border-radius: 10px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .attachment-list {
    flex-wrap: wrap;
    padding: 0 20px;
    .attachment-item {
      width: 560px;
      height: 81px;
      background-color: #f3f5f8;
      border-radius: 20px;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      .attachment-name {
        color: #212531;
        font-size: 18px;
        img {
          margin-right: 10px;
        }
        .text-ellipsis {
          width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .anticon {
        font-size: 20px;
      }
    }
  }
}
</style>
